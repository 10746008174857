import React from 'react';
import Category from 'components/Category';
import { Wrapper, CategoryList } from './styled';
import { useAppState } from 'state/StateProvider';
import * as select from 'state/selectors';
import { updateCategory } from 'state/actions';

const Sidebar: React.FC = () => {
  const [state, dispatch] = useAppState();

  const categories = select.categories(state);
  const isHours = select.isHours(state);
  const isUnitsExceeded = select.isUnitsExceeded(state);

  return (
    <Wrapper>
      <CategoryList>
        {categories.map(category => (
          <Category
            key={category.id}
            category={category}
            isHours={isHours}
            onChange={updated => dispatch(updateCategory(updated))}
            isUnitsExceeded={isUnitsExceeded}
          />
        ))}
      </CategoryList>
    </Wrapper>
  );
};

export default Sidebar;
