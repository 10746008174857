import { LanguagesType } from "enums/translation";

export const setConfigDone = () =>
  <const>{
    type: 'SET_CONFIG_DONE',
  };

export const setUnit = (unit: any) =>
  <const>{
    type: 'SET_UNIT',
    unit,
  };

export const setTotalHours = (totalHours?: number) =>
  <const>{
    type: 'SET_TOTAL_HOURS',
    totalHours,
  };

export const setCourseTitle = (courseTitle?: string) =>
  <const>{
    type: 'SET_COURSE_TITLE',
    courseTitle,
  };

export const setEctsPoints = (ectsPoints?: number) =>
  <const>{
    type: 'SET_ECTS_POINTS',
    ectsPoints,
  };

export const setWeeks = (weeks?: number) =>
  <const>{
    type: 'SET_WEEKS',
    weeks,
  };

export const setCustomLogo = (customLogo?: string) =>
  <const>{
    type: 'SET_CUSTOM_LOGO',
    customLogo,
  };

export const setCategories = (categories: any[]) =>
  <const>{
    type: 'SET_CATEGORIES',
    categories,
  };

export const updateCategory = (updated: any) =>
  ({
    type: 'UPDATE_CATEGORY',
    updated,
  } as const);

  export const setLanguage = (language: LanguagesType) =>
  ({
    type: 'SET_LANGUAGE',
    language,
  } as const);

export type Action = ReturnType<
  | typeof setConfigDone
  | typeof setUnit
  | typeof setTotalHours
  | typeof setCourseTitle
  | typeof setEctsPoints
  | typeof setWeeks
  | typeof setCustomLogo
  | typeof updateCategory
  | typeof setCategories
  | typeof setLanguage
>;
