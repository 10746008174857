import React from 'react';
import styled, { css } from 'styled-components';
import FormGroup from './FormGroup';
import Label from './Label';
import { colors } from 'theme';

const Option = styled.div<{ active?: boolean }>`
  user-select: none;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.gray};
  color: ${colors.black};
  border-radius: 1px;
  height: 2.5rem;
  width: 100%;
  ${({ active }) =>
    active &&
    css`
      background: ${colors.black};
      color: ${colors.white};
    `}
`;

const OptionsWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

type Props = {
  label: string;
  left: string;
  right: string;
  isLeft: boolean;
  onChange: (isLeft: boolean) => void;
};

const Toggle: React.FC<Props> = ({ label, left, right, isLeft, onChange }) => (
  <FormGroup>
    <OptionsWrapper>
      <Option active={isLeft} onClick={() => !isLeft && onChange(true)}>
        {left}
      </Option>
      <Option active={!isLeft} onClick={() => isLeft && onChange(false)}>
        {right}
      </Option>
    </OptionsWrapper>
    <Label>{label}</Label>
  </FormGroup>
);
export default Toggle;
