import styled, { css } from 'styled-components';
import { colors } from 'theme';

export const inputStyles = css`
  width: 100%;
  padding: 1px 4px;
  background: ${colors.inputBackground} !important;
  border: none;
  border-bottom: 3px solid ${colors.black};
  color: ${colors.inputTextColor} !important;
  border-radius: 1px;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  font-size: 1rem;
`;

export const StyledInput = styled.input<{ hasError?: boolean }>`
  ${inputStyles}
  height: 2.5rem;
  border-color: ${({ hasError }) => (hasError ? colors.error : colors.black)};
`;
