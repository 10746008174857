import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import Main from 'components/Main';
import Sidebar from 'components/Sidebar';
import TitleBar from 'components/TitleBar';
import './App.css';
import LoadingScreen from 'components/LoadingScreen';
import { useAppState } from 'state/StateProvider';
import InstructionModal from 'components/InstructionModal';
import { setConfigDone, setCategories, setLanguage } from 'state/actions';
import * as select from 'state/selectors';
import { t } from "@lingui/macro"
import { I18nProvider } from "@lingui/react";
import { i18n } from "./i18n";
import  getCategories from 'data';
import { LanguagesType } from 'enums/translation';

const Wrapper = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 1500px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const Bottom = styled.div`
  display: flex;
`;

const App: React.FC = () => {
  
  const [isModalOpen, setModalOpen] = useState(false);
  const [state, dispatch] = useAppState();

  const isConfigDone = select.isConfigDone(state);

  const changeLanguage = (language: LanguagesType) => {
    dispatch(setLanguage(language));
    i18n.activate(language);
    categories = updateCategoriesLanguage(select.categories(state), getCategories());
  }

  const language = select.language(state);
  i18n.activate(language);
  let categories = getCategories();
  

  document.title = i18n._(t`Studieaktivitetsmodellen`);
  document.title.fontcolor("black");

  useEffect(() => {
    dispatch(setCategories(categories));
    dispatch(setConfigDone());

    if (window.location.hash === '#debug') {
      return;
    }
    
    window.addEventListener('message', e => {
      const categoryConfig = e.data;
      if (categoryConfig && Array.isArray(categoryConfig) &&  categoryConfig.length > 0 && categoryConfig[0].hasOwnProperty('id)')) {
         const customCategories = categories.map(category => {
          return categoryConfig[category.id]
            ? { ...category, ...categoryConfig[category.id] }
            : category;
         }
        );

        dispatch(setCategories(customCategories));
        dispatch(setConfigDone());
      }
    });
  },[]);

  function updateCategoriesLanguage(categories:any[],  translatedCategories:any[]) {
    categories.forEach((category, index) => {
    category.title = translatedCategories[index].title;
    category.description =  translatedCategories[index].description;
    });
    dispatch(setCategories(categories));
    return categories;
  }

  if (!isConfigDone) {
    return <LoadingScreen />;
  }

  return (
    <I18nProvider i18n={i18n} language={language}>
    <Wrapper>
      {isModalOpen && <InstructionModal onClose={() => setModalOpen(false)} />}
      <TitleBar language={language} onLanguageClick={(language) => changeLanguage(language as LanguagesType)} onInfoClick={() => setModalOpen(true)} />
      <Bottom>
        <Main />
        <Sidebar />
      </Bottom>
    </Wrapper>
    </I18nProvider>
  );
};

export default App;
