import React from 'react';
import {Top, Title, InfoLink, Languages, RightPanel} from './styled';
import {Trans} from '@lingui/macro';
import {Button} from 'ui/Form';
import {LanguagesEnum} from '../../enums/translation';

type Props = {
    language: string;
    onInfoClick: () => void;
    onLanguageClick: (language: string) => void;
};

const TitleBar: React.FC<Props> = ({onInfoClick, onLanguageClick, language}) => {
    return (

        <Top>
            <main>
            <h1>
                <Title>
                    <Trans>Studieaktivitets
                        <wbr/>
                        modellen</Trans>
                </Title>
            </h1>
            </main>

            <RightPanel>
            <InfoLink onClick={onInfoClick}><Trans>Læs om aktivitetsmodellen</Trans></InfoLink>
                <Languages>
                    <Button inactive={language !== LanguagesEnum.Danish}
                            onClick={() => onLanguageClick(LanguagesEnum.Danish)}>da</Button><Button
                    inactive={language !== LanguagesEnum.English}
                    onClick={() => onLanguageClick(LanguagesEnum.English)}>{LanguagesEnum.English}</Button>
                </Languages>
            </RightPanel>
        </Top>
    );
};

export default TitleBar;
