import styled from 'styled-components';
import { colors } from 'theme';
export const Top = styled.div`
  display: flex;
  padding-bottom: 1rem;
  justify-content: space-between;
`;
export const Title = styled.div`
  color: ${colors.black};
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 400;
  flex: 1 1 auto;

  @media (min-width: 1024px) {
    font-size: 4rem;
  }
`;
export const InfoLink = styled.div`
  text-decoration: underline;
  align-self: flex-end;
  cursor: pointer;
  white-space: nowrap;
`;

export const Languages = styled.div`
  margin-left: 40px;
  width: 80px;
  display: flex;
  justiffy-content: space-between;
`;

export const RightPanel = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  margin-right: 1px;
`;
