import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 1px;
  background: ${colors.white};
  max-width: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  max-width: 100%;
`;

const Card: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Card;
