import React from 'react';
import Modal from 'components/Modal';
import { Trans } from '@lingui/macro';
import { t } from "@lingui/macro";
import { i18n } from "../../i18n";
type Props = {
  onClose: () => void;
};

const InstructionModal: React.FC<Props> = ({ onClose }) => (
  <Modal
    title={i18n._(t `Vejledning til at udfylde studieaktivitetsmodellen`)}
    onClose={onClose}
  >
    <p>
      <Trans>
        Studieaktivitetsmodellen består af fire kategorier. I kategorierne bliver
        forskellige studieaktiviteter samlet ud fra hvilken kategori, de hører
        mest inde under. Hver kategori er karakteriseret ved, at undervisere og
        studerende har forskellige roller og ansvar.
      </Trans>
    </p>

    <p>
      <Trans>
        Med studieaktivitetsmodellen kan du visualisere hvor stor tidsmæssig vægt,
        der er på hver af de fire kategorier. Forskellige størrelser afspejler
        hvor meget tid den studerende forventes at bruge på studieaktiviteter
        inden for hver af kategorierne.
      </Trans>
    </p>

    <p>
      <Trans>
        Visualiseringen bliver understøttet af konkrete tal, enten i timer eller
        procenter.
      </Trans>
    </p>

    <p>
      <Trans>
        Der er faste beskrivelser af de fire studieaktivitetskategorier. Ligeledes
        er der mulighed for at tilføje fritekst for hver af de fire kategorier,
        som kan være med til at konkretisere for den studerende hvad aktiviteterne
        i hver af de fire kategorier indeholder på netop deres uddannelse.
      </Trans>
    </p>
  </Modal>
);
export default InstructionModal;
