import React from 'react';
import PageOverlay from 'ui/PageOverlay';
import { Loader } from './styled';
import styled from 'styled-components';

const StyledOverlay = styled(PageOverlay as any)`
  background: white;
`;

const LoadingScreen: React.FC = () => {
  return (
    <StyledOverlay>
      <Loader>
        <div className="loader">Loading...</div>
      </Loader>
    </StyledOverlay>
  );
};

export default LoadingScreen;
