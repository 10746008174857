import React from 'react';
import { TextArea, NumberInput } from 'ui/Form';
import {
  Wrapper,
  Indicator,
  Content,
  Left,
  Description,
  Right,
  Title,
} from './styled';
import { t } from "@lingui/macro";
import { i18n } from "../../i18n";

type Props = {
  category: any;
  isHours: boolean;
  isUnitsExceeded: boolean;
  onChange: (updated: any) => void;
};

const Category: React.FC<Props> = ({
  category: { id, color, title, description, info, units },
  isHours,
  onChange,
  isUnitsExceeded,
}) => {
  const unitLabel = isHours ? i18n._(t`timer`) : i18n._(t`procent`);
  const unitDescription =  i18n._(t`Indtast antal ${unitLabel}`);
  const unitError =  i18n._(t`Samlede antal ${unitLabel} oversteget`);
  const error = isHours ? !!units && isUnitsExceeded : isUnitsExceeded;
  const placeHolder = i18n._(t`Indtast beskrivelse`);
  return (
    <Wrapper>
      <Indicator color={color} />
      <Content>
        <Left>
          <div>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </div>
          <NumberInput
            label={unitLabel}
            placeholder={unitDescription}
            id={`category-${id}-units`}
            value={units}
            onChange={(units?: number) => onChange({ id, units })}
            error={error && unitError}
          />
        </Left>
        <Right>
          <TextArea
            id={`category-${id}-description`}
            label={i18n._(t`Beskrivelse (valgfri)`)}
            aria-label={i18n._(t`Beskrivelse (valgfri)`)}
            placeholder={placeHolder}
            value={info}
            onChange={(info: string) => onChange({ id, info })}
          />
        </Right>
      </Content>
    </Wrapper>
  );
};

export default Category;
