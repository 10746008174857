import React from 'react';
import { StyledInput } from './styled';
import FormGroup from './FormGroup';
import Label from './Label';

const isPositiveInteger = (value: string) => /^\d*$/.test(value);

const getDisplayValue = (value?: number) => {
  if (value || value === 0) {
    return value;
  }

  return '';
};

type Props = {
  id: string;
  label: string;
  value?: number;
  onChange: (value?: number) => void;
  error?: boolean | string;
} & Pick<React.HTMLProps<HTMLInputElement>, 'placeholder'>;

const NumberInput: React.FC<Props> = ({
  id,
  label,
  value,
  onChange,
  error,
  placeholder,
}) => (
  <FormGroup>
    <StyledInput
      id={id}
      tabIndex={0}
      type="text"
      value={getDisplayValue(value)}
      placeholder={placeholder}
      hasError={!!error}
      onChange={({
        currentTarget: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        if (!value) {
          onChange();
        } else if (isPositiveInteger(value)) {
          onChange(Number(value));
        }
      }}
    />
    <Label hasError={!!error} htmlFor={id}>
      {error || label}
    </Label>
  </FormGroup>
);
export default NumberInput;
