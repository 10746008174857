import React from 'react';
import Card from 'ui/Card/Card';
import { Wrapper, StyledCross, Title, Content } from './styled';
import PageOverlay from 'ui/PageOverlay';

type Props = {
  title: string;
  onClose: () => void;
};

const Modal: React.SFC<Props> = ({ title, onClose, children }) => {
  return (
    <PageOverlay>
      <Card>
        <Wrapper>
          <StyledCross onClick={onClose} />
          <Title>{title}</Title>
          <Content>{children}</Content>
        </Wrapper>
      </Card>
    </PageOverlay>
  );
};

export default Modal;
