import { Color } from 'csstype';

export const colors: Record<string, Color> = {
  error: 'lightcoral',
  gray: '#aeaeae',
  black: '#2B2B2B',
  white: '#FFF',
  inputBackground: '#f3f3f3',
  inputTextColor: '#000000 !important',
};
