import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { NumberInput, TextInput, Toggle } from 'ui/Form';
import { Wrapper, InputWrapper, StyledRow, UnitsUsed } from './styled';
import { useAppState } from 'state/StateProvider';
import * as select from 'state/selectors';
import {
  setCourseTitle,
  setUnit,
  setEctsPoints,
  setTotalHours,
  setWeeks,
} from 'state/actions';
import { Trans } from '@lingui/macro';
import { t } from "@lingui/macro";
import { i18n } from "../../i18n";

const ChartControls: React.FC = () => {
  const [state, dispatch] = useAppState();

  const isHours = select.isHours(state);
  const totalHours = select.totalHours(state);
  const courseTitle = select.courseTitle(state) || '';
  const weeks = select.weeks(state);
  const ectsPoints = select.ectsPoints(state);
  const isUnitsExceeded = select.isUnitsExceeded(state);
  const unitsUsed = select.unitsUsed(state);
  const unitsLeft = select.unitsLeft(state);

  return (
    <Wrapper>
      <InputWrapper>
        <StyledRow>
          <Col md={8} xs={12}>
            <TextInput
              id="title"
              value={courseTitle}
              placeholder={i18n._(t`Skriv forløbets titel`)}
              label={i18n._(t`Forløbets titel (obligatorisk)`)}
              onChange={(courseTitle: string) =>
                dispatch(setCourseTitle(courseTitle))
              }
            />
          </Col>
          <Col md={4} xs={12}>
            <Toggle
              left={i18n._(t`Timer`)}
              right={i18n._(t`Procent`)}
              isLeft={isHours}
              label={i18n._(t`Enhed`)}
              onChange={(isLeft: boolean) =>
                dispatch(setUnit(isLeft ? 'hours' : 'percent'))
              }
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col md={4} xs={12}>
            <NumberInput
              id="ects"
              value={ectsPoints}
              placeholder={i18n._(t`Indtast antal ECTS`)}
              label={i18n._(t`ECTS point (valgfri)`)}
              onChange={ectsPoints => dispatch(setEctsPoints(ectsPoints))}
            />
          </Col>
          <Col md={4} xs={12}>
            <NumberInput
              id="weeks"
              value={weeks}
              placeholder={i18n._(t`Indtast antal uger`)}
              label={i18n._(t`Uger (valgfri)`)}
              onChange={weeks => dispatch(setWeeks(weeks))}
            />
          </Col>
          {isHours && (
            <Col md={4} xs={12}>
              <NumberInput
                id="total-hours"
                value={totalHours}
                placeholder={i18n._(t`Indtast timeforbrug`)}
                label={i18n._(t`Timer (obligatorisk)`)}
                onChange={totalHours =>
                  dispatch(setTotalHours(totalHours || undefined))
                }
              />
            </Col>
          )}
        </StyledRow>
      </InputWrapper>
      <UnitsUsed error={isUnitsExceeded} bottom={isHours}>
        <p>
          <Trans>Du har fordelt</Trans> {unitsUsed}&nbsp;{isHours ?  <Trans>timer</Trans> : '%'}{' '}
          {totalHours && !isUnitsExceeded && i18n._(t`(${unitsLeft} tilbage)`)}
        </p>
      </UnitsUsed>
    </Wrapper>
  );
};

export default ChartControls;
