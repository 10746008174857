import React from 'react';
import ChartControls from 'components/ChartControls';
import {Button} from 'ui/Form';
import {exportPdf, exportPng} from 'util/export';
import ImageInput from 'ui/Form/ImageInput';
import PieChart from 'components/PieChart';
import {
    Wrapper,
    Center,
    LogoContainer,
    Bottom,
    LeftButtonWrapper,
    RightButtonWrapper,
    StyledExportIcon,
} from './styled';
import {colors} from 'theme';
import {useAppState} from 'state/StateProvider';
import * as select from 'state/selectors';
import {setCustomLogo} from 'state/actions';
import {Trans} from '@lingui/macro';
import {t} from "@lingui/macro";
import {i18n} from "../../i18n";

type Props = { pieId?: string; };

const Main: React.FC<Props> = ({pieId = 'squarePie'}) => {
    const [state, dispatch] = useAppState();

    const categories = select.categories(state);
    const chartError = select.isUnitsExceeded(state);
    const courseTitle = select.courseTitle(state);
    const customLogo = select.customLogo(state);
    const ectsPoints = select.ectsPoints(state);
    const isExportable = select.isExportable(state);
    const isHours = select.isHours(state);
    const totalHours = select.totalHours(state);
    const unit = select.unit(state);
    const unitsLeft = select.unitsLeft(state);
    const weeks = select.weeks(state);
    const pieChartTitle = i18n._(t`Ubrugte timer`);

    return (
        <Wrapper>
            <ChartControls/>
            <Center>
                <PieChart
                    data={[
                        ...categories,
                        {
                            id: -1,
                            title: pieChartTitle,
                            description: '',
                            units: unitsLeft,
                            color: colors.gray,
                        },
                    ]}
                    error={chartError}
                    isHours={isHours}
                    id={pieId}
                    size={400}
                />
                <LogoContainer id="logo-container"/>
            </Center>
            <Bottom>
                <LeftButtonWrapper>
                    <ImageInput
                        onChange={cusotmLogo => dispatch(setCustomLogo(cusotmLogo))}
                        helpText={i18n._(t`valgfri`)}
                        imgSrc={customLogo}
                    >
                    </ImageInput>
                </LeftButtonWrapper>
                <RightButtonWrapper>
                    <Button
                        disabled={!isExportable}
                        onClick={async () => {
                            exportPng({
                                courseTitle,
                                weeks,
                                ectsPoints,
                                totalHours,
                                categories,
                                unit,
                                customLogo,
                                pieDomId: pieId,
                            });
                        }}
                    >Export PNG <i className={isExportable ? "font-awesome-icon fa fa-download" : "font-awesome-icon-black fa fa-download"}/>
                    </Button>
                    <Button
                        disabled={!isExportable}
                        onClick={() => {
                            exportPdf({
                                courseTitle,
                                weeks,
                                ectsPoints,
                                totalHours,
                                categories,
                                unit,
                                customLogo,
                                pieDomId: pieId,
                            });
                        }}
                    >Export PDF <i className={isExportable ? "font-awesome-icon fa fa-download" : "font-awesome-icon-black fa fa-download"}/>
                    </Button>
                </RightButtonWrapper>
            </Bottom>
        </Wrapper>
    );
};

export default Main;
