import React from 'react';
import { StyledInput } from './styled';
import FormGroup from './FormGroup';
import Label from './Label';

type Props = {
  id: string;
  label: string;
  value?: string;
  onChange: (value: string) => void;
} & Pick<React.HTMLProps<HTMLInputElement>, 'placeholder'>;

const TextInput: React.FC<Props> = ({
  id,
  label,
  placeholder,
  value,
  onChange,
}) => (
  <FormGroup>
    <StyledInput
      id={id}
      tabIndex={0}
      type="text"
      value={value || ''}
      placeholder={placeholder}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.currentTarget.value)
      }
    />
    <Label htmlFor={id}>{label}</Label>
  </FormGroup>
);
export default TextInput;
