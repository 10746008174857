import styled from 'styled-components';
export const Wrapper = styled.div`
  width: 35%;
  min-height: 100%;
`;
export const CategoryList = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-row-gap: 1rem;
  height: 100%;
`;
