import { Action } from './actions';
import { i18n } from '../i18n';
import { LanguagesEnum, LanguagesType } from '../enums/translation';

const getDefaultLanguage = ():LanguagesType => {
  const navigatoreShortLanguage = navigator.language.slice(0, 2);
  if (i18n.availableLanguages.includes(navigatoreShortLanguage)) {
    return navigatoreShortLanguage as LanguagesType;
  }
  return LanguagesEnum.English as LanguagesType;
}
const defaultLanguage = getDefaultLanguage();
const initialState = {
  isConfigDone: false,
  unit: 'hours',
  categories: [] as any[],
  language: defaultLanguage
};

export type RootState = Partial<{
  courseTitle: string;
  totalHours: number;
  ectsPoints: number;
  weeks: number;
  customLogo: string;
}> &
  typeof initialState;

const reducer = (state: RootState, action: Action): RootState => {
  switch (action.type) {
    case 'SET_CONFIG_DONE':
      return { ...state, isConfigDone: true };
    case 'SET_UNIT':
      return { ...state, unit: action.unit, totalHours: undefined };
    case 'SET_TOTAL_HOURS':
      return { ...state, totalHours: action.totalHours };
    case 'SET_COURSE_TITLE':
      return { ...state, courseTitle: action.courseTitle };
    case 'SET_ECTS_POINTS':
      return { ...state, ectsPoints: action.ectsPoints };
    case 'SET_WEEKS':
      return { ...state, weeks: action.weeks };
    case 'SET_CUSTOM_LOGO':
      return { ...state, customLogo: action.customLogo };
    case 'SET_CATEGORIES':
      return { ...state, categories: action.categories };
    case 'UPDATE_CATEGORY':
      const updated = action.updated;
      return {
        ...state,
        categories: state.categories.map(category =>
          category.id !== updated.id ? category : { ...category, ...updated },
        ),
      };
      case 'SET_LANGUAGE':
        return { ...state, language: action.language };
    default:
      return state;
  }
};

export { initialState };
export default reducer;
