import styled from 'styled-components';
import { colors } from 'theme';

interface LabelProps {
    hasError?: boolean;
    center?: boolean;
    htmlFor?: string;
}

const Label = styled.label<LabelProps>`
    display: block;
    padding: 0.25rem 0.25rem;
    color: ${({ hasError }) => (hasError ? colors.error : colors.black)};
    text-align: ${({ center }) => (center ? 'center' : 'left')};

    &::first-letter {
        text-transform: uppercase;
    }
`;

export default Label;
