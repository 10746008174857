import React, { useRef } from 'react';
import styled from 'styled-components';
import FormGroup from './FormGroup';
import HelpText from './Label';
import { colors } from 'theme';
import {t, Trans} from "@lingui/macro";
import {i18n} from "../../i18n";

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
`;

interface LabelProps {
    hasError?: boolean;
    center?: boolean;
    htmlFor?: string;
}

const Label = styled.label<LabelProps>`
  width: 15rem;
  max-width: 100%;

  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.5px;
  cursor: pointer;

  height: 5rem;
  line-height: 5rem;
  padding: 0 1rem;
  vertical-align: middle;
  border: 1px dotted ${colors.black};
`;

const LogoWrapper = styled.div`
  width: 15rem;
`;

const Logo = styled.img`
  display: block;
  margin: auto;
  height: 5rem;
  width: auto;
`;

const RemoveLogo = styled.div`
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.error};
  margin-top: 0.5rem;
`;

type Props = {
  onChange(dataUrl: string): void;
  helpText?: string;
  imgSrc?: string;
};

const ImageInput: React.FC<Props> = ({
  helpText,
  imgSrc,
  onChange,
  children,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const clickInput = () => {
    const input = fileInputRef.current;
    if (input) {
      input.click();
    }
  };

  return (
    <FormGroup>
      <StyledInput
        onChange={e => {
          const files = e.currentTarget.files;
          if (files && files.length) {
            const file = files.item(0) as File;
            const reader = new FileReader();
            reader.onload = e => {
              onChange(reader.result as string);
            };

            reader.readAsDataURL(file);
          }
        }}
        ref={fileInputRef}
        accept="image/*"
        aria-label={i18n._(t`Tilføj institutionens logo`)}
        type="file"
        id="logoUpload"
        tabIndex={1}
      />
      {imgSrc ? (
        <LogoWrapper>
          <Logo src={imgSrc} onClick={clickInput} />
          <RemoveLogo
            onClick={() => {
              const input = fileInputRef.current;
              if (input) {
                input.value = '';
                onChange('');
              }
            }}
          >
            Fjerne
          </RemoveLogo>
        </LogoWrapper>
      ) : (
        <>
          <Label onClick={clickInput}>{children}</Label>
          <HelpText center>{helpText}</HelpText>
        </>
      )}
      <Label htmlFor="logoUpload"><Trans>Tilføj institutionens logo</Trans></Label>
    </FormGroup>

  );
};

export default ImageInput;
