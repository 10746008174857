import React, { useRef, useEffect } from 'react';
import PieHelper from './PieHelpers';
import { Wrapper } from './styled';
import { colors } from 'theme';

type Props = {
  id: string;
  data: any[];
  error?: boolean;
  isHours: boolean;
  size: number;
};

const PieChart: React.FC<Props> = ({ id, data, error, isHours, size }) => {
  const svgRef = useRef<SVGSVGElement>(null);

  const radius = size / 2;
  const circleRadius = radius * 0.9;
  const clipRadius = circleRadius * 0.9;

  useEffect(() => {
    const pieHelper = new PieHelper(
      svgRef.current,
      circleRadius,
      (7 / 4) * Math.PI,
      isHours,
      error,
      data,
    );
    pieHelper.update();
  }, [data, error, circleRadius, isHours]);

  return (
    <Wrapper>
      <svg
        id={id}
        viewBox={`0 0 ${size} ${size}`}
        ref={svgRef}
        style={{
          height: '90%',
          maxHeight: '90%',
          maxWidth: '65%',
          width: '65%',
        }}
      >
        <defs>
          <g>
            <clipPath id="pieClip">
              <circle r={clipRadius} />
            </clipPath>
          </g>
          <filter id="errorFilter">
            <feGaussianBlur result="coloredBlur" stdDeviation="4" />
          </filter>
        </defs>
        <g id="translateGroup" transform={`translate(${[radius, radius]})`}>
          <circle r={clipRadius} fill={colors.gray} />
          <g id="clipGroup" clipPath="url(#pieClip)" />
          <circle r="40" fill={colors.white} />
          <g transform={`translate(${[-20, -30]}), scale(0.2)`}>
            <path d="M58.831 57.692c15.648 0 28.334-12.686 28.334-28.334 0-15.649-12.686-28.335-28.334-28.335-15.649 0-28.335 12.686-28.335 28.335 0 15.648 12.686 28.334 28.335 28.334" />
            <path d="M115.298 121.068c0-1.219-.053-2.425-.127-3.625v-1.297c0-.962-.145-1.889-.405-2.766-3.793-28.094-28.051-49.693-57.282-49.265C29.392 64.527 5.935 85.68 1.98 112.9a10.049 10.049 0 0 0-.681 3.62v37.662c0 5.574 4.519 10.095 10.095 10.095 5.574 0 10.093-4.521 10.093-10.095V120.38c1.208-8.885 3.696-17.02 7.141-23.924v75.01c-.001.038-.006.075-.006.113v100.903c0 5.369 4.352 9.72 9.72 9.72h5.726a9.719 9.719 0 0 0 9.72-9.72v-92.497h8.825v92.497c0 5.369 4.353 9.72 9.721 9.72h5.726c5.368 0 9.719-4.351 9.719-9.72V171.579a9.91 9.91 0 0 0-.095-1.297V96.331c4.967 9.901 7.957 22.356 8.029 35.911v22.313c0 5.368 4.352 9.721 9.72 9.721h.017c5.368 0 9.721-4.353 9.721-9.721v-33.412l.127-.075z" />
            <path d="M132.297 66.108c15.647 0 28.335-12.686 28.335-28.335 0-15.649-12.688-28.334-28.335-28.334-15.649 0-28.335 12.685-28.335 28.334s12.685 28.335 28.335 28.335" />
            <path d="M194.562 158.683l-4.855-33.347c-2.147-29.798-27.2-53.252-57.646-52.805-27.925.409-51.267 21.312-55.43 48.295-.118.436-.219.879-.28 1.339l-4.926 37.339c-.729 5.527 3.16 10.598 8.687 11.327 5.527.729 10.598-3.16 11.327-8.688l4.927-37.338c.049-.369.073-.734.082-1.097 1.649-7.835 3.934-13.651 6.757-17.966v39.109a5.715 5.715 0 0 0-.206 1.331L83.446 213.03c0 6.07 4.922 10.992 10.993 10.992h8.759v56.875c0 5.368 4.352 9.721 9.72 9.721h5.727c5.367 0 9.72-4.353 9.72-9.721v-56.875h8.826v56.875c0 5.368 4.351 9.721 9.72 9.721h5.726c5.368 0 9.721-4.353 9.721-9.721v-56.875h6.177c6.071 0 10.993-4.922 10.993-10.992l-17.268-66.814v-41.03c3.297 4.608 5.808 10.868 7.651 19.228l5.396 37.072c.774 5.312 5.706 8.991 11.02 8.218l.017-.002c5.311-.774 8.99-5.707 8.218-11.019" />
            <path
              fill="#FFF"
              d="M152.636 290.868h-5.726c-5.497 0-9.97-4.473-9.97-9.971v-56.625h-8.326v56.625c0 5.498-4.472 9.971-9.97 9.971h-5.727c-5.498 0-9.97-4.473-9.97-9.971v-56.625h-8.509c-6.199 0-11.243-5.043-11.243-11.242l.01-.07 19.542-66.816c.015-.424.082-.858.205-1.324v-38.217c-2.706 4.386-4.758 10.01-6.262 17.158a9.315 9.315 0 0 1-.079 1.078l-4.927 37.338c-.676 5.126-5.08 8.993-10.243 8.993-.453 0-.912-.03-1.365-.09-5.654-.747-9.648-5.954-8.902-11.608l4.926-37.339c.056-.417.146-.854.287-1.372 2.028-13.155 8.735-25.323 18.88-34.235 10.231-8.988 23.296-14.047 36.787-14.245.288-.004.572-.006.859-.006 14.551 0 28.416 5.471 39.04 15.404 10.57 9.884 16.963 23.251 18 37.64l4.854 33.329c.791 5.44-2.99 10.51-8.43 11.303-.496.072-.983.107-1.465.107h-.001c-4.908 0-9.145-3.669-9.854-8.534l-5.396-37.072c-1.736-7.868-4.08-13.925-7.154-18.467v40.201l17.268 66.846c0 6.199-5.044 11.242-11.243 11.242h-5.927v56.625c.001 5.497-4.471 9.969-9.969 9.969zm-24.522-67.096h9.326v57.125c0 5.223 4.248 9.471 9.47 9.471h5.726c5.222 0 9.471-4.248 9.471-9.471v-57.125h6.427c5.913 0 10.727-4.802 10.743-10.711l-17.268-66.846v-41.809l.453.633c3.338 4.665 5.854 10.985 7.692 19.32l5.399 37.09c.674 4.621 4.697 8.106 9.359 8.106h.001c.457 0 .92-.034 1.376-.1 5.184-.755 8.775-5.571 8.024-10.738l-4.855-33.347c-1.03-14.281-7.367-27.531-17.846-37.329-10.738-10.041-24.805-15.486-39.55-15.263-13.372.196-26.322 5.21-36.464 14.12-10.057 8.835-16.706 20.896-18.722 33.963-.14.524-.227.939-.279 1.334l-4.926 37.339c-.71 5.381 3.09 10.336 8.472 11.046.431.057.868.086 1.299.086 4.913 0 9.104-3.679 9.747-8.558l4.927-37.338c.044-.331.07-.681.08-1.07 1.606-7.654 3.829-13.558 6.798-18.096l.459-.702-.009 40.013a5.372 5.372 0 0 0-.197 1.273l-.01.063-19.542 66.812c.02 5.907 4.831 10.707 10.743 10.707h9.009v57.125c0 5.223 4.248 9.471 9.47 9.471h5.727c5.222 0 9.47-4.248 9.47-9.471v-57.123z"
            />
          </g>
        </g>
      </svg>
    </Wrapper>
  );
};

export default PieChart;
