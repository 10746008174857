import React from 'react';
import styled from 'styled-components';
import { inputStyles } from './styled';
import FormGroup from './FormGroup';
import Label from './Label';

const StyledTextArea = styled.textarea`
  ${inputStyles}
  height: 100%;
  resize: none;
`;

const StyledFormGroup = styled(FormGroup as any)`
  flex: 1;
`;

type Props = {
  id: string;
  label: string;
  onChange: (value: string) => void;
} & Pick<React.HTMLProps<HTMLTextAreaElement>, 'placeholder' | 'value'>;

const TextArea: React.FC<Props> = ({
  id,
  placeholder,
  label,
  value,
  onChange,
}) => (
  <StyledFormGroup>
    <StyledTextArea
      id={id}
      tabIndex={0}
      placeholder={placeholder}
      aria-label={label}
      value={value || ''}
      onChange={({ currentTarget: { value } }) => onChange(value)}
    />
    <Label htmlFor={id}>{label}</Label>
  </StyledFormGroup>
);
export default TextArea;
