import { RootState } from './reducer';

export const isConfigDone = ({ isConfigDone }: RootState) => isConfigDone;

export const unit = ({ unit }: RootState) => unit;

export const totalHours = ({ totalHours }: RootState) => totalHours;

export const courseTitle = ({ courseTitle }: RootState) => courseTitle;

export const weeks = ({ weeks }: RootState) => weeks;

export const ectsPoints = ({ ectsPoints }: RootState) => ectsPoints;

export const categories = ({ categories }: RootState) => categories;

export const customLogo = ({ customLogo }: RootState) => customLogo;

export const isHours = ({ unit }: RootState) => unit === 'hours';

export const unitsUsed = ({ categories }: RootState) =>
  categories.reduce((totalUnits, { units }) => totalUnits + (units || 0), 0);

export const unitsLeft = (state: RootState) => {
  const { totalHours } = state;
  const used = unitsUsed(state);

  if (isHours(state)) {
    return totalHours ? totalHours - used : 0;
  }

  return 100 - used;
};

export const isUnitsExceeded = (state: RootState) => {
  const { totalHours } = state;
  const left = unitsLeft(state);
  return isHours(state) ? !!totalHours && left < 0 : left < 0;
};

export const isExportable = (state: RootState) => {
  const { courseTitle, totalHours } = state;
  const courseFilled = !!courseTitle && !!courseTitle.trim();
  const totalFilled = !!totalHours || !isHours(state);
  const categoriesFilled = unitsLeft(state) === 0;

  return courseFilled && totalFilled && categoriesFilled;
};

export const language = ({ language }: RootState) => language;
