import styled from 'styled-components';
import { ReactComponent as Cross } from 'resources/cross.svg';
export const StyledCross = styled(Cross)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  width: 1.5rem;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;
export const Title = styled.h1`
  font-size: 1.5rem;
  margin: 1rem 0;
`;
export const Content = styled.div`
  width: 35rem;
  max-width: 90vw;
  & > p {
    margin-bottom: 1rem;
    line-height: 1.2;
    font-size: 1.1rem;
  }
`;
