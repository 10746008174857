import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { colors } from 'theme';

export const Wrapper = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

export const UnitsUsed = styled.div<{ error: boolean; bottom: boolean }>`
  flex-shrink: 1;
  width: 8.5rem;
  max-width: 8.5rem;
  display: flex;
  align-items: ${({ bottom }) => (bottom ? 'flex-end' : 'flex-start')};
  margin-bottom: 2.5rem;
  color: ${({ error }) => (error ? colors.error : colors.black)};

  & > p {
    font-size: 0.9rem;
  }
`;

export const StyledRow = styled(Row as any)`
  & > div {
    margin-bottom: 0.9rem;
  }
`;
