import { t } from "@lingui/macro";
import { i18n } from "./i18n";

export default function getCategories(): any[] {
  return  [
    {
      id: 1,
      title:  i18n._(t`Kategori 1`),
      color: '#F84557',
      description:  i18n._(t`Undervisere har hovedansvaret for studieaktiviteterne, og studerende har et medansvar gennem forberedelse og deltagelse. Både studerende og underviser deltager.`),
    },
    {
      id: 2,
      title: i18n._(t`Kategori 2`),
      color: '#21567D',
      description:i18n._(t`Undervisere har hovedansvaret for rammesætning af læringsaktiviteterne, og studerende har hovedansvar for aktiv deltagelse i de tilrettelagte studieaktiviteter. Kun studerende deltager.`),
    },
    {
      id: 3,
      title: i18n._(t`Kategori 3`),
      color: '#ffae0c',
      description: i18n._(t`Studerende har hovedansvaret for studieaktiviteterne, og undervisere har medansvar for at rammerne er til stede. Kun studerende deltager.`),
    },
    {
      id: 4,
      title: i18n._(t`Kategori 4`),
      color: '#4A1956',
      description: i18n._(t`Studerende har hovedansvaret for læringsaktiviteterne, og undervisere har medansvar for at rammerne er til stede. Både studerende og underviser deltager.`),
    },
  ]
};
