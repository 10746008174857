import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme';

const StyledButton = styled.button<{ disabled?: boolean, inactive?: boolean }>`
  width: 15rem;
  max-width: 100%;
  text-decoration: none;
  background-color: ${({ disabled, inactive }) =>
    (disabled || inactive) ? colors.gray : colors.black};
  color: ${({ disabled, inactive }) =>
      (disabled || inactive) ? colors.black : colors.white};
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.5px;
  cursor: ${({ disabled }) =>
  disabled ? 'auto' : 'pointer'};
  outline: 0;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1rem;
  vertical-align: middle;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
`;

type Props = {
  disabled?: boolean;
  inactive?: boolean;
  onClick?: () => void;
};

const Button: React.FC<Props> = ({ disabled, inactive, children, onClick }) => (
  <StyledButton inactive={inactive} disabled={disabled} tabIndex={1} onClick={onClick}>
    {children}
  </StyledButton>
);

export default Button;
