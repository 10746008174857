import * as d3 from 'd3';
import { colors } from 'theme';
import { t } from "@lingui/macro";
import { i18n } from "../../i18n";

class PieHelper {
  constructor(svgElement, radius, startAngle, showHours, error, data) {
    this.svg = d3.select(svgElement);
    this.translateGroup = this.svg.select('#translateGroup');
    this.clipGroup = this.svg.select('#clipGroup');
    this.radius = radius;

    const total = data.reduce(
      (total, { units }) => total + Math.max(units || 0, 0),
      0,
    );

    this.data = data.map(category => ({
      ...category,
      percent: Math.round((100 * category.units) / total),
    }));

    this.data.push({
      id: 'dash-control',
      untis: 0,
      color: '#CCC',
    });

    this.error = error;
    this.showHours = showHours;

    this.pie = d3
      .pie()
      .value(d => d.units || 0)
      .sort(null)
      .sortValues(null)
      .startAngle(startAngle)
      .endAngle(startAngle + Math.PI * 2);

    this.pies = this.pie(this.data);
  }

  update = () => {
    this.updateError();
    this.updateArcs();
    this.updateHours();
    this.updatePercent();
    this.updatePieNumbers();
  };

  updateError = () => {
    const errorRect = this.translateGroup.select('#error');

    if (this.error && errorRect.empty()) {
      this.translateGroup
        .append('g')
        .attr('id', 'error')
        .append('circle')
        .attr('r', this.radius * 0.9)
        .style('fill-opacity', 0)
        .attr('stroke', 'lightcoral')
        .attr('stroke-width', 8)
        .style('filter', 'url(#errorFilter)');
    }

    if (!this.error && !errorRect.empty()) {
      errorRect.remove();
    }
  };

  updateArcs = () => {
    // Determine where to put dashed lines
    const dashIds = new Set();
    const nonZeroUnits = this.data.filter(d => d.units > 0);

    if (nonZeroUnits.length > 1) {
      if (nonZeroUnits.length % 2 !== 0) {
        dashIds.add('dash-control');
      }
      for (let i = 1; i < nonZeroUnits.length; i += 2) {
        dashIds.add(nonZeroUnits[i].id);
      }
    }

    // Match DOM order to data order
    const hasArcs = document.getElementsByClassName('arcs').length !== 0;
    if (hasArcs) {
      for (let d of this.data) {
        const node = document.getElementById(`arc-${d.id}`);
        node.parentNode.appendChild(node);
      }
    }

    const paths = this.clipGroup.selectAll('.arcs').data(this.pies);

    paths.exit().remove();

    const enterPaths = paths
      .enter()
      .append('path')
      .attr('fill', ({ data: { color } }) => color)
      .attr('stroke', 'white')
      .attr('stroke-dasharray', '6 6')
      .attr('class', 'arcs')
      .attr('id', d => `arc-${d.data.id}`);

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(this.radius);

    enterPaths
      .merge(paths)
      .attr('d', arc)
      .attr('stroke-width', d => {
        return dashIds.has(d.data.id) ? 3 : 0;
      });

    paths
      .transition()
      .duration(500)
      .ease(d3.easeCubic)
      .attrTween('d', function(d, i) {
        const start = this._current || {
          endAngle: d.startAngle,
          startAngle: d.startAngle,
        };

        var interpolate = d3.interpolate(start, d);
        this._current = d;

        // Don't close the path for dash-control
        if (d.data.id === 'dash-control') {
          const path = arc(d).slice(0, -1);
          return t => path;
        }

        return t => arc(interpolate(t), i);
      });

    // Rearange DOM so that dashed arcs are rendered above other arcs
    if (hasArcs) {
      for (let id of dashIds) {
        const node = document.getElementById(`arc-${id}`);
        node.parentNode.appendChild(node);
      }
    }
  };

  updateHours = () => {
    if (this.showHours) {
      this.appendDefultText('hour-text', ({ data: { units } }, i) => {
        if (i === 4) return;
        return !units ? '' : i18n._(t`${units} timer`);
      });
    } else {
      this.translateGroup.selectAll('.hour-text').remove();
    }
  };

  updatePercent = () => {
    this.appendDefultText('percent-text', ({ data: { units, percent } }, i) => {
      return i === 4 ? '' : !units ? '' : percent + '%';
    }).attr('dy', () => (this.showHours ? '1.1em' : '0px'));
  };

  appendDefultText(textClass, textGetter) {
    const text = this.translateGroup.selectAll(`.${textClass}`).data(this.pies);

    text.exit().remove();

    const enterText = text
      .enter()
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('class', textClass)
      .style('fill', colors.white)
      .style('font-size', '0.9rem')
      .attr('font-family', 'Roboto')
      .style('font-weight', '700');

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(this.radius * 1.2);

    return enterText
      .merge(text)
      .text(textGetter)
      .attr('transform', d => `translate(${arc.centroid(d)})`);
  }

  updatePieNumbers = () => {
    const pieNumber = this.translateGroup
      .selectAll('.outer-text')
      .data(this.pies);

    pieNumber.exit().remove();

    const enterPieNumber = pieNumber
      .enter()
      .append('text')
      .attr('class', 'outer-text')
      .attr('text-anchor', 'middle')
      .style('fill', colors.black)
      .style('font-size', '1.1rem')
      .attr('font-family', 'Roboto')
      .style('font-weight', '700');

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(this.radius * 2);

    enterPieNumber
      .merge(pieNumber)
      .text((d, i) => (i === 4 || !d.value ? '' : i + 1))
      .attr('transform', d => 'translate(' + arc.centroid(d) + ')');
  };
}

export default PieHelper;
