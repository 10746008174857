import styled, { css } from 'styled-components';
import { ReactComponent as ExportIcon } from 'resources/export.svg';
import { colors } from 'theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 65%;
  margin-right: 2rem;
  flex: 1;
`;
export const Center = styled.div`
  position: relative;
  flex: 1;
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const LeftButtonWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
`;
export const RightButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 0.5rem;
  }
`;
const iconStyles = css`
  fill: ${colors.white};
  width: 1.5rem;
  vertical-align: middle;
`;
export const StyledExportIcon = styled(ExportIcon)`
  ${iconStyles}
`;
export const LogoContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
`;
