import React, { createContext, useReducer, useContext } from 'react';
import reducer, { initialState, RootState } from './reducer';
import { Action } from './actions';

const Context = createContext<[RootState, React.Dispatch<Action>]>([
  initialState,
  (null as any) as React.Dispatch<Action>,
]);

export const StateProvider: React.FC = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return <Context.Provider value={value} children={children} />;
};

export function useAppState() {
  return useContext(Context);
}
