import { setupI18n } from "@lingui/core";
import da from "./locales/da/messages";
import en from "./locales/en/messages";

export const i18n = setupI18n({
  catalogs: {
    da,
    en
  }
});
