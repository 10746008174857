// @ts-ignore
import * as svgExport from 'save-svg-as-png';
import jsPDF from 'jspdf';
import logo from 'resources/logo.png';
import {saveAs} from 'file-saver';
import {colors} from 'theme';
import {i18n} from "../i18n";
import {t} from "@lingui/macro"
import {toast} from "react-toastify";
// TODO: This could be made prettier and easier to understand with some common config.
//       There are some implicit dependencies between different blocks
//       Consider prefetching instad of dynamic imports

const pdfConifg = {
    a4Width: 297,
    a4Height: 210,
};


export const exportPng = async (config: any) => {
    const pdfjsLib = await import(
        /* webpackChunkName: "pdfjs" */ 'pdfjs-dist/webpack'
        );
    // TODO: Relative path doesn't seem to be working on gh-pages even with homepage set
    const doc = await createPdf(config);
    const pdf = doc.output('arraybuffer');

    const pdfDocument = await pdfjsLib.default.getDocument(pdf).promise;
    const page = await pdfDocument.getPage(1);

    var viewport = page.getViewport({scale: 5});
    const {width, height} = viewport;

    var canvas = document.createElement('canvas');
    canvas.height = height;
    canvas.width = width;

    var renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport: viewport,
    };

    // @ts-ignore
    await page.render(renderContext).promise;

    canvas.toBlob(blob => {
        if (blob) {
            saveAs(blob, i18n._(t`Studieaktivitetsmodellen`) + '.png');
        }
    });
};
export const exportPdf = async (config: any) => {
    var toastId = toast('Generate PDF', {
        progress: 0
    });
    const pdf = await createPdf(config);
    var pdfData = pdf.output('blob');
    return blobToBase64(pdfData)
        .then(base64String => {
            toast.update(toastId, {
                progress: 50
            })
            fetch("https://dev.ironpdf.itoperators.dk/wcag?token=a2c952e755fed3d220e3a452c1866aad", {
                method: 'POST',
                body: base64String,
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(response => {
                toast.update(toastId, {
                    progress: 75
                })
                response.text().then(body => {
                    toast.update(toastId, {
                        progress: 99
                    })
                    var url = convertBase64ToDataUrl(body);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = i18n._(t`Studieaktivitetsmodellen`) + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                    toast.done(toastId)
                    return true;
                })
            }).catch(error => {
                new Error("Error creating PDF!")
                console.error("PDF creation error:"+error);
                return false;
            });
        })
        .catch(error => {
            new Error("Error creating PDF!")
            console.error('Error converting Blob to base64:', error);
            return false;
        });
};


function blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject(new Error('Failed to read Blob as base64'));
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

function convertBase64ToDataUrl(base64Data: string) {
    var blob = convertBase64ToBlob(base64Data);
    var data_url = window.URL.createObjectURL(blob);
    return data_url;
}

function convertBase64ToBlob(base64Data: string) {
    // Extract content type from base64 string
    const contentTypeMatch = base64Data.match(/^data:([A-Za-z-+\/]+);base64/);
    if (!contentTypeMatch) {
        throw new Error('Invalid base64 string format');
    }
    const contentType = contentTypeMatch[1];

    // Remove data URL prefix and extract base64 content
    const base64Content = base64Data.replace(/^data:([A-Za-z-+\/]+);base64,/, '');

    // Convert base64 to binary
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // Create blob
    return new Blob([byteArray], {type: contentType});
}


function uint8ArrayToBase64(array: Uint8Array): string {
    let binary = '';
    for (let i = 0; i < array.length; i++) {
        binary += String.fromCharCode(array[i]);
    }
    return window.btoa(binary);
}


const createPdf = async (config: any) => {
    const {categories, customLogo, pieDomId} = config;

    const jsPDF = await import('jspdf');
    const doc = new jsPDF.default({
        orientation: 'landscape',
        format: 'a4',
    });

    await loadFonts(doc);
    doc.setFont('Roboto');

    writeHeader(doc, config);
    await writeChart(doc, pieDomId);
    writeCategories(doc, categories);
    await writeCustomLogo(doc, customLogo);
    //await writeLogo(doc);

    return doc;
};

const writeHeader = (
    doc: jsPDF,
    {courseTitle, totalHours, ectsPoints, weeks}: any,
) => {
    const largeMargin = 15;
    const smallMargin = 6;

    const marginLeft = 13;
    let marginTop = 17;
    doc.setFontStyle('bold');
    doc.setTextColor(colors.black as any);

    doc.setFontSize(14);
    doc.text(i18n._(t`Studieaktivitetsmodellen`), marginLeft, marginTop);
    marginTop += largeMargin;

    doc.setFontSize(20);
    doc.text(courseTitle, marginLeft, marginTop);
    marginTop += smallMargin + 1;

    doc.setFontSize(14);

    if (totalHours) {
        doc.text(i18n._(t`${totalHours} timer i alt`), marginLeft, marginTop);
        marginTop += smallMargin;
    }

    if (ectsPoints) {
        doc.text(`${ectsPoints} ECTS points`, marginLeft, marginTop);
        marginTop += smallMargin;
    }

    if (weeks) {
        doc.text(i18n._(t`${weeks} uger`), marginLeft, marginTop);
    }
};

const writeChart = async (doc: jsPDF, chartId: string) => {
    const chartElement = document.getElementById(chartId);
    const {a4Height, a4Width} = pdfConifg;
    const side = a4Width * 0.45;
    const marginTop = (a4Height - side) / 1.5;
    const marginLeft = (a4Width * 0.55 - side) / 2;
    if (chartElement instanceof SVGElement) {
        const chart = await svgExport.svgAsPngUri(chartElement, {scale: 4});
        doc.addImage(chart, 'PNG', marginLeft, marginTop, side, side);

    } else {
        doc.text('Failed to load chart', marginLeft, marginTop);
    }
};

const loadFonts = async (doc: jsPDF) => {
    if (!doc.existsFileInVFS('Roboto-normal.ttf')) {
        const robotoNormal = await import('resources/robotoNormal');
        const robotoBold = await import('resources/robotoBold');
        doc.addFileToVFS('Roboto-normal.ttf', robotoNormal.default);
        doc.addFileToVFS('Roboto-bold.ttf', robotoBold.default);

        doc.addFont('Roboto-normal.ttf', 'Roboto', 'normal');
        doc.addFont('Roboto-bold.ttf', 'Roboto', 'bold');
    }
};

const writeCategories = (doc: jsPDF, categories: any[]) => {
    const {a4Height, a4Width} = pdfConifg;

    const marginTop = 12;
    const marginLeft = a4Width * 0.55;

    const barWidth = 4;
    const barHeight = a4Height * 0.2;
    const spaceBetween = 4;

    const textMarginLeft = marginLeft + 2 * barWidth;
    const textMarginRight = 10;
    const textMaxWidth = a4Width - textMarginLeft - textMarginRight;

    const titleMarginTop = marginTop + 8;
    const descriptionMarginTop = titleMarginTop + 5;
    const infoMarginTop = descriptionMarginTop + 5;

    categories.forEach((category, index) => {
        doc.setFillColor(category.color);
        doc.rect(
            marginLeft,
            marginTop + (barHeight + spaceBetween) * index,
            barWidth,
            barHeight,
            'F',
        );

        //doc.setTextColor(category.color);
        doc.setFontStyle('bold');

        doc.setFontSize(16);
        doc.text(
            category.title,
            textMarginLeft,
            titleMarginTop + (barHeight + spaceBetween) * index,
        );

        doc.setFontSize(9);
        const descriptionLines = doc.splitTextToSize(
            category.description,
            textMaxWidth,
        );
        doc.text(
            descriptionLines,
            textMarginLeft,
            descriptionMarginTop + (barHeight + spaceBetween) * index,
        );

        if (category.info) {
            doc.setTextColor(0);
            doc.setFontStyle('normal');
            doc.text(
                doc.splitTextToSize(category.info, textMaxWidth),
                textMarginLeft,
                doc.getTextDimensions(descriptionLines).h +
                infoMarginTop +
                (barHeight + spaceBetween) * index,
            );
        }
    });
};

const writeCustomLogo = (doc: jsPDF, dataUrl?: string) => {
    return new Promise(resolve => {
        if (dataUrl) {
            const img = new Image();
            img.onerror = () => resolve(false);
            img.onload = () => {
                let imageWidth = 30;
                let imageHeight = img.naturalHeight * (imageWidth / img.naturalWidth);
                if (imageHeight > 25) {
                    imageHeight = 25;
                    imageWidth = img.naturalWidth * (imageHeight / img.naturalHeight);
                }
                const marginBottom = 5;
                doc.addImage(
                    dataUrl,
                    10,
                    193 - imageHeight - marginBottom,
                    imageWidth,
                    imageHeight,
                );
                resolve(true);
            };
            img.src = dataUrl;
        } else {
            resolve(false);
        }
    });
};

const writeLogo = (doc: jsPDF) =>
    new Promise(resolve => {
        const img = new Image();

        img.onerror = () => resolve();
        img.onload = () => {
            doc.addImage(img, 'PNG', 10, 193, 65, 6.5);
            resolve();
        };
        img.src = logo;
    });
