import styled from 'styled-components';
import { Color } from 'csstype';

export const Indicator = styled.div<{ color: Color }>`
  width: 1.4rem;
  min-width: 1.4rem;
  background-color: ${({ color }) => color};
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Content = styled.div`
  max-width: 100%;
  display: flex;
  padding: 1rem;
  padding-right: 0;
  flex-grow: 1;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1rem;
  width: 50%;

  & > :not(:first-child) {
    margin-top: 1rem;
  }

  & > :last-child {
    margin-bottom: 0;
    padding: 0;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
  & > :last-child {
    margin: 0;
    padding: 0;
  }
`;

export const Title = styled.p`
  font-size: 1.2rem;
`;

export const Description = styled.p`
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.1;
`;
